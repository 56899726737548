<template>
  <b-modal :active.sync="modalAtivo" has-modal-card>
    <div class="modal-card modal-editar-venda">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <b>Ingressos</b>
        </p>
        <p class="modal-card-subtitle">Para alterar a data do ingresso, selecione uma nova data no campo e digite sua senha de alteração.</p>
      </header>
      <section class="modal-card-body">
        <b-table
          :data="detalhes"
          checkable
          :checked-rows.sync="checkedChangeData"
          checkbox-position="right"
          :is-row-checkable="row => row.lenght"
          hoverable
          mobile-cards
        >
          <b-table-column field="tipo" label="Tipo" centered v-slot="props">
            <span class="is-capitalized">
              {{ props.row.tipo }}
            </span>
          </b-table-column>

          <b-table-column field="quantidade" label="Quantidade" numeric centered v-slot="props">
            {{ props.row.quantidade }}
          </b-table-column>

          <b-table-column field="valorUnitario" label="Valor Unitário" numeric centered v-slot="props">
            {{ props.row.valorUnitario | maskCurrency }}
          </b-table-column>

          <b-table-column field="valorTotal" label="Valor Total" numeric centered v-slot="props">
            {{ props.row.valorTotal | maskCurrency }}
          </b-table-column>

          <b-table-column field="data" label="Data" centered v-slot="props">
            <b-input type="date" v-model="props.row.data" :min="diaAtual" @input="handleChangeData(props.row)" />
          </b-table-column>
        </b-table>
        <b-field v-if="ingressosEdit.length > 0" label="Senha" message="Para prosseguir informe a senha de alteração" style="margin-top: 30px">
          <b-input type="password" v-model="senha" required />
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" name="cancelar" @click="cancel">
          Cancelar
        </button>
        <button class="button is-primary" style="float: right" name="confirmar" type="submit" @click="confirmarEdicaoVenda">
          Salvar
        </button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import vendaService from '@/services/vendaService';
import { formatDateToInput } from '@/helpers/helpers';

export default {
  name: 'ModalEditarVenda',
  props: {
    ativo: {
      type: Boolean,
      default: false,
    },
    venda: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalAtivo: false,
      checkedChangeData: [],
      detalhes: [],
      ingressosEdit: [],
      senha: '',
    };
  },
  watch: {
    ativo(novoValor) {
      this.modalAtivo = novoValor;
    },
    modalAtivo(novoValor) {
      if (!novoValor) {
        this.cancel();
      }
    },
    venda(novoValor) {
      const detalhes = JSON.parse(JSON.stringify(novoValor.detalhes));
      this.getDataDetalhes(detalhes);
    },
  },
  computed: {
    diaAtual() {
      return formatDateToInput(new Date());
    },
  },
  methods: {
    handleChangeData(ingresso) {
      const indexIngresso = this.ingressosEdit.findIndex(i => i.id === ingresso.id);
      if (indexIngresso >= 0) {
        this.ingressosEdit.splice(indexIngresso, 1, ingresso);
      } else {
        this.ingressosEdit.push(ingresso);
        this.checkedChangeData.push(ingresso);
      }
    },
    getDataDetalhes(detalhes) {
      this.detalhes = detalhes.map(detalhe => {
        detalhe.data = formatDateToInput(new Date(detalhe.data).addDays(1));
        return detalhe;
      });
    },
    confirmarEdicaoVenda() {
      const bodyRequest = {
        senha: this.senha,
        detalhes: this.ingressosEdit,
      };

      if (bodyRequest.detalhes && bodyRequest.senha) {
        vendaService
          .editar(bodyRequest)
          .then(() => {
            this.$buefy.snackbar.open({
              duration: 3000,
              message: 'Ingresso(s) editada com sucesso',
              type: 'is-success',
              position: 'is-bottom-right',
              actionText: 'OK',
              queue: false,
            });
            //this.$emit('changed');
          })
          .catch(() => {
            this.$buefy.snackbar.open({
              duration: 3000,
              message: 'Erro ao editar ingresso!',
              type: 'is-danger',
              position: 'is-bottom-right',
              actionText: 'OK',
              queue: false,
            });
          })
          .finally(() => {
            this.ingressosEdit = [];
            this.senha = '';
            this.modalAtivo = false;
          });
      }
    },
    cancel() {
      this.$emit('cancel');
      this.ingressosEdit = [];
      this.checkedChangeData = [];
      this.$emit('changed');
    },
  },
};
</script>
<style lang="scss">
.modal-editar-venda {
  width: 1000px !important;
  max-width: 90%;
  margin: auto !important;

  .modal-card-head {
    align-items: flex-start;
    flex-direction: column;

    .modal-card-subtitle {
      margin-top: 5px;
    }
  }
}
</style>
