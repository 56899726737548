<template>
  <b-modal :active.sync="modalAtivo" has-modal-card>
    <form class="modal-card" @submit="confirm">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <b>Confirmar Venda</b>
        </p>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <b-field label="Forma de pagamento">
              <b-select
                v-model="form.formaPagamentoId"
                placeholder="Selecione"
                required
                expanded
              >
                <option :value="3">MercadoPago</option>
                <option :value="4">Transferência</option>
              </b-select>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" name="cancelar" @click="cancel">
          Cancelar
        </button>
        <button
          class="button is-primary"
          style="float: right"
          name="confirmar"
          type="submit"
        >
          Confirmar
        </button>
      </footer>
    </form>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalConfirmarVenda',
  props: {
    ativo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalAtivo: false,
      form: { formaPagamentoId: 3 },
    };
  },
  watch: {
    ativo(novoValor) {
      this.modalAtivo = novoValor;
    },
    modalAtivo(novoValor) {
      if (!novoValor) {
        this.cancel();
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm(event) {
      event.preventDefault();
      this.$emit('confirm', { ...this.form });
      this.form = {};
    },
  },
};
</script>
