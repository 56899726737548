<template>
  <section class="section" id="relatorio-vendas">
    <div class="container-admin">
      <div class="columns is-multiline">
        <div class="column is-full">
          <h3 class="title is-3 has-text-primary">
            Relatório de Vendas
          </h3>
        </div>
        <div class="column is-one-fifth">
          <b-field label="Tipo de Filtro">
            <b-select expanded v-model="tipoDeFiltro">
              <option value="data">Data</option>
              <option value="vendaId">Número Pedido</option>
              <option value="nome">Nome</option>
              <option value="cpf">CPF</option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-one-quarter" v-if="tipoDeFiltro === 'data'">
          <b-field label="Data Inicial">
            <b-input type="date" v-model="filtro.dataI" :max="filtro.dataF" />
          </b-field>
        </div>
        <div class="column is-one-quarter" v-if="tipoDeFiltro === 'data'">
          <b-field label="Data Final">
            <b-input type="date" v-model="filtro.dataF" :min="filtro.dataI" />
          </b-field>
        </div>
        <div class="column is-one-quarter" v-if="tipoDeFiltro === 'data'">
          <b-field label="Pago">
            <b-checkbox v-model="filtro.pago" />
          </b-field>
        </div>
        <div class="column is-one-quarter" v-if="tipoDeFiltro === 'vendaId'">
          <b-field label="Número do Pedido">
            <input class="input" v-model="filtro.vendaId" />
          </b-field>
        </div>
        <div class="column is-one-quarter" v-if="tipoDeFiltro === 'nome'">
          <b-field label="Nome">
            <input class="input" v-model="filtro.nome" />
          </b-field>
        </div>
        <div class="column is-one-quarter" v-if="tipoDeFiltro === 'cpf'">
          <b-field label="CPF">
            <input class="input" v-model="filtro.cpf" />
          </b-field>
        </div>
        <div class="column is-one-fifth" style="display: flex;align-items: flex-end;">
          <b-button
            type="is-secondary"
            expanded
            :disabled="
              (tipoDeFiltro === 'data' && (!filtro.dataI || !filtro.dataF)) ||
                (tipoDeFiltro === 'vendaId' && !filtro.vendaId) ||
                (tipoDeFiltro === 'nome' && !filtro.nome) ||
                (tipoDeFiltro === 'cpf' && !filtro.cpf)
            "
            @click="getVendas"
          >
            Buscar
          </b-button>
        </div>
      </div>
      <hr />
      <div class="columns" v-if="agrupamentoVendas.length > 0 && !isLoading">
        <div class="column">
          <div class="card card-valores">
            <h5 class="title is-5">Total Período</h5>
            <p class="is-size-1 has-text-primary">
              {{ totalPeriodo | maskCurrency }}
            </p>
          </div>
        </div>
        <div class="column">
          <div class="card card-valores">
            <h5 class="title is-5">Total Recebido</h5>
            <p class="is-size-1 has-text-success">
              {{ totalPagoPeriodo | maskCurrency }}
            </p>
          </div>
        </div>
      </div>
      <section v-if="agrupamentoVendas.length === 0 && !isLoading" class="section has-background-light has-text-centered" style="border-radius: 10px">
        Nenhum resultado encontrado para a busca.
      </section>
      <div v-for="vendasAgrupadas in agrupamentoVendas" :key="vendasAgrupadas.data">
        <hr />
        <div class="header-sales-group">
          <div class="date-baloon">
            <h5 class="title is-5">{{ vendasAgrupadas.data }}</h5>
          </div>
          <div class="wrapper-valores">
            <div class="valor-baloon">
              <h5>
                Recebido:
                <span class="has-text-success">
                  {{ vendasAgrupadas.totalPagoPeriodo | maskCurrency }}
                </span>
              </h5>
            </div>
            <div class="valor-baloon">
              <h5>
                Total:
                <span class="has-text-primary">
                  {{ vendasAgrupadas.totalPeriodo | maskCurrency }}
                </span>
              </h5>
            </div>
          </div>
        </div>

        <b-table :data="vendasAgrupadas.vendas" :loading="isLoading" hoverable mobile-cards detailed detail-key="id" show-detail-icon>
          <b-table-column field="id" label="ID" v-slot="props">
            {{ props.row.id }}
          </b-table-column>

          <b-table-column field="nome" label="Nome" centered v-slot="props"> {{ props.row.nome }} {{ props.row.sobrenome }} </b-table-column>

          <b-table-column field="valorTotal" label="Valor Total" centered v-slot="props">
            {{ props.row.valorTotal | maskCurrency }}
          </b-table-column>

          <b-table-column field="valorPago" label="Valor Pago" centered v-slot="props">
            {{ props.row.valorPago | maskCurrency }}
          </b-table-column>

          <b-table-column field="pago" label="Pago" centered v-slot="props">
            <b-icon :icon="props.row.pago ? 'check-bold' : 'close-thick'" :type="props.row.pago ? 'is-success' : 'is-danger'"></b-icon>
          </b-table-column>

          <b-table-column label="Ações" centered v-slot="props">
            <b-button @click="abrirDetalhes(props.row)">
              <b-icon icon="magnify-plus-outline" />
            </b-button>

            <b-button style="margin-left: 10px" v-if="!props.row.pago" type="is-success" @click="confirmarVenda(props.row)">
              Confirmar
            </b-button>

            <b-button style="margin-left: 10px" v-if="props.row.pago" type="is-danger" @click="cancelarVenda(props.row)">
              Cancelar
            </b-button>

            <b-button style="margin-left: 10px" type="is-secondary" @click="editarDetalheVenda(props.row)">
              Editar
            </b-button>

            <b-button style="margin-left: 10px" v-if="!props.row.pago" type="is-primary" @click="enviarmsg(props.row)">
              Enviar WhatsApp
            </b-button>
          </b-table-column>

          <template slot="detail" slot-scope="props">
            <b-table :data="props.row.detalhes" :loading="isLoading" hoverable mobile-cards>
              <b-table-column field="tipo" label="Tipo" centered v-slot="props">
                <span class="is-capitalized">
                  {{ props.row.tipo }}
                </span>
              </b-table-column>

              <b-table-column field="quantidade" label="Quantidade" numeric centered v-slot="props">
                {{ props.row.quantidade }}
              </b-table-column>

              <b-table-column field="valorUnitario" label="Valor Unitário" numeric centered v-slot="props">
                {{ props.row.valorUnitario | maskCurrency }}
              </b-table-column>

              <b-table-column field="valorTotal" label="Valor Total" numeric centered v-slot="props">
                {{ props.row.valorTotal | maskCurrency }}
              </b-table-column>

              <b-table-column field="data" label="Data" centered v-slot="props">
                {{ props.row.data | maskDate }}
              </b-table-column>
            </b-table>
          </template>
        </b-table>
      </div>

      <ModalEditarVenda :ativo="modalEditVendaAtivo" :venda="vendaSelecionada" @cancel="fecharModalEditarVenda" @changed="handleChangeData" />
      <ModalCancelarVenda :ativo="modalCancelarVendaAtivo" @cancel="fecharModalCancelarVenda" @confirm="confirmarCancelamentoVenda" />
      <ModalConfirmarVenda :ativo="modalConfirmarVendaAtivo" @cancel="cancelarConfirmacaoVenda" @confirm="salvarConfirmacaoVenda" />
      <ModalDetalheVenda :ativo="modalDetalheVendaAtivo" :venda="vendaSelecionada" @fechar="fecharDetalhes" />
    </div>
  </section>
</template>

<script>
import ModalConfirmarVenda from '@/components/Relatorios/Vendas/ModalConfirmarVenda';
import ModalCancelarVenda from '@/components/Relatorios/Vendas/ModalCancelarVenda';
import ModalEditarVenda from '@/components/Relatorios/Vendas/ModalEditarVenda';
import ModalDetalheVenda from '@/components/Relatorios/ModalDetalheVenda';
import vendaService from '@/services/vendaService';
import { maskDate, formatDateToInput } from '@/helpers/helpers';

export default {
  name: 'RelatorioDeVendas',
  components: {
    ModalDetalheVenda,
    ModalCancelarVenda,
    ModalEditarVenda,
    ModalConfirmarVenda,
  },
  data() {
    return {
      tipoDeFiltro: 'data',
      filtro: {
        dataI: formatDateToInput(new Date()),
        dataF: formatDateToInput(new Date().addDays(1)),
        pago: true,
      },
      vendaObject: {},
      isLoading: false,
      modalCancelarVendaAtivo: false,
      modalEditVendaAtivo: false,
      modalConfirmarVendaAtivo: false,
      modalDetalheVendaAtivo: false,
      vendaSelecionada: {},
      vendas: [],
    };
  },
  computed: {
    agrupamentoVendas() {
      if (this.vendas.length > 0) {
        let arrayDatas = [];
        if (this.tipoDeFiltro === 'data') {
          const { dataI, dataF } = this.filtro;
          arrayDatas = this.getDateRange(dataI, dataF);
        } else {
          arrayDatas = [maskDate(this.vendas[0].dataCadastro)];
        }

        const agrupamento = [];

        arrayDatas.forEach(data => {
          const vendasPorData = this.vendas.filter(i => maskDate(i.dataCadastro) === data);

          const totalPeriodo = this.calcularValorTotal(vendasPorData);
          const totalPagoPeriodo = this.calcularValorPago(vendasPorData);

          if (vendasPorData.length > 0) {
            agrupamento.push({
              data,
              vendas: vendasPorData,
              totalPeriodo,
              totalPagoPeriodo,
            });
          }
        });

        return agrupamento;
      }

      return [];
    },
    totalPagoPeriodo() {
      return this.calcularValorPago(this.vendas);
    },
    totalPeriodo() {
      return this.calcularValorTotal(this.vendas);
    },
  },
  created() {
    this.getVendas();
  },
  methods: {
    getVendas() {
      if (this.tipoDeFiltro == 'data') {
        if (this.filtro.dataI && this.filtro.dataF) {
          this.isLoading = true;
          vendaService
            .getByRangeData(this.filtro)
            .then(({ data }) => {
              this.vendas = data;
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
      if (this.tipoDeFiltro === 'vendaId') {
        if (this.filtro.vendaId) {
          this.isLoading = true;
          vendaService
            .getByIdPrivate(this.filtro.vendaId)
            .then(({ data }) => {
              this.vendas = [data];
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
      if (this.tipoDeFiltro === 'nome') {
        if (this.filtro.nome) {
          this.isLoading = true;
          vendaService
            .getByName(this.filtro.nome)
            .then(({ data }) => {
              this.vendas = data;
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
      if (this.tipoDeFiltro === 'cpf') {
        if (this.filtro.cpf) {
          this.isLoading = true;
          vendaService
            .getByCpf(this.filtro.cpf)
            .then(({ data }) => {
              this.vendas = [data];
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      }
    },
    getDateRange(startDate, endDate) {
      const dateArray = new Array();
      let currentDate = new Date(startDate);
      while (currentDate <= new Date(endDate)) {
        dateArray.push(maskDate(currentDate));
        currentDate = currentDate.addDays(1);
      }
      return dateArray;
    },
    abrirDetalhes(venda) {
      this.vendaSelecionada = venda;
      this.modalDetalheVendaAtivo = true;
    },
    fecharDetalhes() {
      this.vendaSelecionada = {};
      this.modalDetalheVendaAtivo = false;
    },
    calcularValorTotal(vendas) {
      return vendas.map(venda => venda.valorTotal).reduce((acc, valor) => parseFloat(acc) + parseFloat(valor), 0);
    },
    calcularValorPago(vendas) {
      return vendas.map(venda => venda.valorPago).reduce((acc, valor) => parseFloat(acc) + parseFloat(valor), 0);
    },
    cancelarVenda(venda) {
      this.vendaObject = venda;
      this.modalCancelarVendaAtivo = true;
    },
    enviarmsg(venda) {
      let telefone = '55' + venda.telefone;
      let msg =
        'Oi, tudo bem?\n Somos da agência Olimpia Parques.\n Observamos que você realizou a seleção dos ingressos.\n Você está com alguma dificuldade na finalização da sua compra?';
      let url = 'https://api.whatsapp.com/send?phone=' + telefone + '&text=' + msg;

      window.open(url, '_blank');
    },
    editarDetalheVenda(venda) {
      this.vendaSelecionada = venda;
      this.modalEditVendaAtivo = true;
    },
    fecharModalEditarVenda() {
      this.modalEditVendaAtivo = false;
    },
    handleChangeData() {
      this.getVendas();
    },
    fecharModalCancelarVenda() {
      this.modalCancelarVendaAtivo = false;
    },
    confirmarCancelamentoVenda(bodyRequest) {
      vendaService
        .cancelar(this.vendaObject, bodyRequest)
        .then(() => {
          this.getVendas();
          this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Venda cancelada com sucesso',
            type: 'is-success',
            position: 'is-bottom-right',
            actionText: 'OK',
            queue: false,
          });
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Erro ao cancelar venda!',
            type: 'is-danger',
            position: 'is-bottom-right',
            actionText: 'OK',
            queue: false,
          });
        })
        .finally(() => {
          this.vendaObject = null;
          this.modalCancelarVendaAtivo = false;
          this.getVendas();
        });
    },
    confirmarVenda(venda) {
      this.vendaObject = venda;
      this.modalConfirmarVendaAtivo = true;
    },
    cancelarConfirmacaoVenda() {
      this.modalConfirmarVendaAtivo = false;
    },
    salvarConfirmacaoVenda(bodyRequest) {
      vendaService
        .confirmar(this.vendaObject, bodyRequest)
        .then(() => {
          this.getVendas();
          this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Venda confirmada com sucesso',
            type: 'is-success',
            position: 'is-bottom-right',
            actionText: 'OK',
            queue: false,
          });
        })
        .catch(() => {
          this.$buefy.snackbar.open({
            duration: 3000,
            message: 'Erro ao confirmar a venda!',
            type: 'is-danger',
            position: 'is-bottom-right',
            actionText: 'OK',
            queue: false,
          });
        })
        .finally(() => {
          this.vendaObject = null;
          this.modalConfirmarVendaAtivo = false;
          this.getVendas();
        });
    },
  },
};
</script>

<style lang="scss">
#relatorio-vendas {
  .header-sales-group {
    .date-baloon {
      display: inline-block;
      background-color: #ff6d01;
      width: 200px;
      padding: 18px;
      border-radius: 15pc;
      text-align: center;
      margin-bottom: 20px;
      margin-right: 10px;
      box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
      h5 {
        color: #fff;
      }
    }

    .valor-baloon {
      float: right;
      width: fit-content;
      padding: 14px;
      border-radius: 10px;
      text-align: center;
      margin-bottom: 20px;
      margin-right: 10px;
      box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);

      h5 {
        display: flex;
        align-items: center;
        font-weight: 500;
        span {
          font-size: 20px;
          margin-left: 5px;
        }
      }
    }
  }

  .b-table {
    .table tr.detail {
      background-color: whitesmoke;
      .detail-container {
        padding: 0;
        .table {
          background-color: whitesmoke;
        }
      }
    }

    td {
      vertical-align: middle;
    }
  }

  .card-valores {
    border-radius: 10px;
    padding: 2rem;
    h5 {
      margin-bottom: 5px;
    }
  }
}
</style>
